import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRequest } from '../util/APIUtils';
import { Grid, Container, Button, Card, CardContent, CardActions, CardHeader, Stack, Typography } from '@mui/material';

const MyTests = ({ currentUser }) => {
  const [tests, setTests] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const initialFetchRef = useRef(false);

  useEffect(() => {
    if (!initialFetchRef.current) {
      initialFetchRef.current = true;
      fetchTests();
    }
  }, [currentUser]);

  const fetchTests = async () => {
    try {
      const result = await getRequest(`/api/atq/recent-tests?page=${page}&size=8`);

      if (result.content.length < 8) {
        setHasMore(false);
      }

      setTests(prevTests => [...prevTests, ...result.content]);
    } catch (error) {
      console.error("Error fetching the tests:", error);
    }
  };

  const handleViewMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  const handleDetail = (attemptedTestId, examName, quizTitle, date, totalScore) => {
    navigate('/detail', { state: { attemptedTestId: attemptedTestId, examName: examName, quizTitle: quizTitle, date: date, totalScore: totalScore} })
  };
  useEffect(() => {
    if (page > 0) {
      fetchTests();
    }
  }, [page]);

  return (
    <Container sx={{ py: 5 }}>
            <Grid container spacing={1}>

      {tests.map((test, index) => (
        <Grid item xs={12} md={3} display={'flex'} justifyContent="center" alignItems="center">
        <Card key={index} sx={{ width: {xs:'80%', sm:250} }} elevation={5} style={{ display: 'flex',justifyContent:'space-between', flexDirection:'column'}}>          
          <CardContent alignContent="top">
            <Stack direction="row" sx={{justifyContent:"center"}}>
              <Typography variant="h5">{test.examName}</Typography>
            </Stack>
            <Stack direction="row" sx={{justifyContent:"center"}}>
              <Typography variant="h6">{test.quizTitle}</Typography>
            </Stack>
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography variant="body1" align="left">Test Taken On:</Typography>
              <Typography variant="body1" align="right" color="text.secondary">{new Date(test.date).toLocaleDateString()}</Typography>
            </Stack>
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography variant="body1" align="left">{test.examName == 'IELTS' ? "Band:":"Score:"}</Typography>
              <Typography variant="body1" align="right" color="text.secondary">{test.totalScore}</Typography>
            </Stack>
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography variant="body1" align="left">Total Questions:</Typography>
              <Typography variant="body1" align="right">{test.totalQuestions}</Typography>
            </Stack>
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography variant="body1" align="left">Attempted Questions:</Typography>
              <Typography variant="body1" align="right">{test.totalAttemptedQuestions}</Typography>
            </Stack>
            {test.quizId == 23? '':
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography variant="body1" align="left">Correct Questions:</Typography>
              <Typography variant="body1" align="right">{test.correct}</Typography>
            </Stack>}
            {test.quizId == 23? '':
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography variant="body1" align="left">Incorrect Questions:</Typography>
              <Typography variant="body1" align="right">{test.incorrect}</Typography>
            </Stack>
              }
            <Stack direction="row" sx={{justifyContent:"space-between"}}>
              <Typography variant="body1" align="left">Did Not Attempt:</Typography>
              <Typography variant="body1" align="right">{test.didNotAttempt}</Typography>
            </Stack>
          </CardContent>
          <CardActions style={{justifyContent: 'center'}}>
              <Button variant="outlined" sx={{textTransform:'none'}} onClick={() => handleDetail(test.attemptedTestId, test.examName, test.quizTitle, new Date(test.date).toLocaleDateString(), test.totalScore)}>
                View Details
              </Button>
          </CardActions>      
        </Card>
        </Grid>
      ))}
      </Grid>
    

      <Grid container spacing={5} margin={1}>
        <Grid item xs={12} md={12} lg={12} display={'flex'} justifyContent={'center'}>
              {hasMore && (
            <Button variant="contained" onClick={handleViewMore}>
              View More
            </Button>)}
        </Grid>
      </Grid>
      
    </Container>
  );
};

export default MyTests;
