import React from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardContent,Grid, Typography,  Container, useMediaQuery, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Exam from '../components/Exam.js'
import examData from '../data/exam.json'


const StyledCard = styled(Card)(({ theme }) => ({
  animation: 'blink-animation 2s infinite',
  '@keyframes blink-animation': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  backgroundColor: '#fff',
  color: 'black',
  border: 'none',
  boxShadow: 'none'
}));




function Home({allowedExams, authenticated}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <main>
            <Container sx={{ py: isSmallScreen ? 1:1 }}>
              <Grid item xs={12}>
                <StyledCard>
                <CardContent sx={{
                                display: 'flex',                
                               justifyContent: 'center',       
                                fontSize: isSmallScreen ? '12px' : '16px', 
                                flexWrap: 'wrap',  
                                textAlign: isSmallScreen ? 'center' : 'left', 
                                   width: '100%',        
                          }}
                 >
                    Use code &nbsp;<b>FREE100</b>&nbsp; at checkout to get FREE access to the tests
                     every month.
               </CardContent>

          </StyledCard>               
              </Grid>
               <Grid container>
                {examData &&
                  examData.map((item) => {
                      let hasAccess = false
                      if(allowedExams){
                        hasAccess = allowedExams.split(",").includes(String(item.id))
                      }
                    return <Exam key={item.id} exam={item} hasAccess={hasAccess} authenticated={authenticated}/>
                  }
                )}
                
              </Grid>
            </Container>
          </main>
  );
}

export default Home;


